<template>
  <div class="Orderlistpar">
    <div class="top-titles">
      <div class="title">
          <span>工单列表</span>/工单详情
      </div>
    </div>
    <!-- 详情-->
    <div class="particulars">
      <div class="particulars-box">
          <div class="particulars-list" ><span class="pl-tltle">标题:</span><span class="pl-content">{{listpar.title}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">会员名称:</span><span class="pl-content">{{listpar.wk_name}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">工厂名称:</span><span class="pl-content">{{listpar.bsr_employername}}</span></div>
          <!-- <div class="particulars-list" ><span class="pl-tltle">手机号:</span><span class="pl-content">{{listpar.wkbu_mobile}}</span></div> -->
          <div class="particulars-list" ><span class="pl-tltle">报名时间:</span><span class="pl-content">{{listpar.enroll_time}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">开工时间:</span><span class="pl-content">{{listpar.begin_datetime}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">工单状态:</span><span class="pl-content">{{listpar.status_name}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">招聘方:</span><span class="pl-content">{{listpar.company_name}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">工时:</span><span class="pl-content">{{listpar.wkhours_sum}}</span></div>
          <div class="particulars-list" ><span class="pl-tltle">费用:</span><span class="pl-content">{{listpar.price_toplat}}</span></div>
          <!-- <div class="particulars-list" ><span class="pl-tltle">总佣金: </span><span class="pl-content">{{listpar.commission_sum}}</span></div> -->
      </div>
    </div>
      <div class="form-btn" v-if="(baseGetSeparate == 2 && status == 1 )?true:false ">
       <el-button type="primary" class="biy-icon" icon="el-icon-check" @click="luyong()">录用</el-button>
        <el-button  type="primary"  class="biy-icon"  icon="el-icon-close"  @click="dialogFormVisible = true" >拒绝</el-button>
      </div>

    <el-dialog title="请输入拒绝理由" :visible.sync="dialogFormVisible" width="450px">
      <el-form :model="listpar" label-width="80px">
            <el-form-item label="拒绝理由" >
                  <el-input v-model="listpar.liyou"></el-input>
          </el-form-item>
      </el-form>
          <div div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="jujue()">确 定</el-button>
            </div>
        </el-dialog>

            <!-- 选择列表  表格-->
    <div class="table-form">
      <el-table :data="list" row-class-name="tableRowStyle" v-loading="loading"> 

        <el-table-column prop="dwk_name" label="佣金收益人"> </el-table-column>
        <el-table-column prop="dst_chainmode" label="关系"> </el-table-column>
        <el-table-column prop="dbu_mobile" label="手机号"> </el-table-column>
        <el-table-column prop="commission" label="佣金"> </el-table-column>
        <el-table-column prop="biz_dotime" label="落位日期"> </el-table-column>

      </el-table>
      <!-- 分页器 -->
      <div class="block" style="margin-top: 15px">
        <el-pagination
          align="center"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

  </div>  
</template>
<script>
import fakelist from "@/api/fakelist.js"
import apiurl from "@/api/apiurl/apiurl.js"
import config from "@/request/config.js"
export default {
  data() {
    return {
            // 分页
      currentPage: 1, // 当前页码
      total: 10,
      pageSize: 10, // 每页的数据条数
      // 上传图片的地址
      upLoadUrl:config.ImgUrl,
      listpar:{},
      list:[],
      dialogFormVisible:false,
     loading: this.$common.loading,

      baseGetSeparate:this.$common.baseGetSeparate(),
      status:this.$route.query.status,
    };
  },

  created(){
     this.getSong();
     this.getSongs();
 
  },
  mounted() {},

  methods: {
    //查询接口
      async getSong(){
				let res = await apiurl.entityDataHandle({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        handleMode:'fetchWorkInfo',
        bizCatalog:'TodoWorkEntity',
        workId:this.$route.query.id,
        })
    
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.listpar = res.Result;
      },

    // //查询接口
      async getSongs(){
				let res = await apiurl.fetchPageData({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        pageIndex: this.currentPage,
        pageSize: this.pageSize,
        bizCatalog:'TodoWorkEntity',
        handleMode:'fetchallWorkItemCommission',
        query:{
          workId:this.$route.query.id,
        }, })
        console.log(res);
        if(this.$common.verifyAjaxResult(res,false)==false) return;
           this.list = res.Result.pageData
           this.total = res.Result.count
           this.loading = false
      },

    //弹窗
      luyong(){
          this.$confirm('是否确认录用', '消息提示', {
          distinguishCancelAndClose: true,
          confirmButtonText: '确认',
          cancelButtonText: '取消操作'
        }).then(() => {
          this.luyongs()
          }).catch(action => {
            console.log("取消");
          })
      },
     async luyongs(){
      let res = await apiurl.entityDataHandles({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      phone:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'TodoWorkEntity',
        handleMode:'auditEnroll',
        workId:this.$route.query.id,
        todo:'allow',
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.$alert('审核通过', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push({ path: `/home/orderlist`});
          }
        });
      },

     async jujue(){
    	let res = await apiurl.entityDataHandles({
      FOREND_TOKEN:this.$common.baseGetToken(),//统一传递一下前端token 
      loginName:this.$common.baseGetuSername(),//登录账号 
      phone:this.$common.baseGetuSername(),//登录账号 
      loginType:this.$common.baseGetSeparate(),//区分用户和商家1用户2商家
        bizCatalog:'TodoWorkEntity',
        handleMode:'auditEnroll',
        workId:this.$route.query.id,
        todo:'refuse',
        reason:this.listpar.liyou
        })
        if(this.$common.verifyAjaxResult(res,false)==false) return;
          this.$alert('拒绝成功', '消息提示', {
          confirmButtonText: '确定',
          callback: action => {
            this.$router.push({ path: `/home/orderlist`});
          }
        });
      },

          // 分页 每页条数改变时触发 选择一页显示多少行
    handleSizeChange(val) {
      this.pageSize = val;
      this.getSong();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getSong();
    },
  },
  computed: {},
};
</script>


<style  scoped>
.form-btn {
  width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 100px;
}
.form-btn .biy-icon {
  width: 160px;
  border-radius: 10px;
}
.form-btn .biy-icon:nth-of-type(2) {
background-color: #2ab6708a;
}
@import "../../assets/css/parstyle.css";

</style>